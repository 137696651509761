import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  const dashboardPages = [
    'screens', 'playlists', 'media',
    'layouts', 'triggers', 'shelf-labels',
    'reports-content', 'reports-triggers',
  ]

  const isDashboardPage = dashboardPages.some(pageName => pathname.includes(pageName));

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboardPage} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // {
    //   path: '/',
    //   element: <Navigate to="/screens" replace />,
    // },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: 'data',
      children: [
        { path: 'traffic/:partnerId', element: <PageAnalyticsTraffic /> },
        { path: 'demographic/:partnerId', element: <PageAnalyticsDemographic /> },
      ],
    },
    {
      path: '/',
      element: (<AuthGuard><DashboardLayout /></AuthGuard>),
      children: [
        { element: <Navigate to="/screens" replace />, index: true },
        {
          path: 'screens',
          children: [
            { element: <Navigate to="/screens/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard roles={[1,2,3]} hasContent><PageScreensList /></RoleBasedGuard> },
            { path: 'manage/:uid', element: <RoleBasedGuard roles={[1,2,3]} hasContent><PageScreenManage /></RoleBasedGuard> },
          ],
        },

        {
          path: 'video-walls',
          children: [
            { element: <Navigate to="/video-walls/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard roles={[1,2,3]} hasContent><PageVideoWallsList /></RoleBasedGuard> },
            { path: 'manage/:uid', element: <RoleBasedGuard roles={[1,2,3]} hasContent><PageVideoWallManage /></RoleBasedGuard> },
          ],
        },
       
        {
          path: 'wbc',
          children: [
            { element: <Navigate to="/wbc/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard roles={[1,2,3]} hasContent><PageWbcList /></RoleBasedGuard> },
            { path: 'manage/:uid', element: <RoleBasedGuard roles={[1,2,3]} hasContent><PageWbcManage /></RoleBasedGuard> },
          ],
        },

        {
          path: 'playlists',
          children: [
            { element: <Navigate to="/playlists/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard roles={[1,2,3]} hasContent><PagePlaylistsList /></RoleBasedGuard> },
            { path: 'manage/:uid', element: <RoleBasedGuard roles={[1,2,3]} hasContent><PagePlaylistManage /></RoleBasedGuard> },
          ],
        },

        {
          path: 'media',
          children: [
            { element: <Navigate to="/media/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard roles={[1,2,3]} hasContent><PageMediaList /></RoleBasedGuard> },
          ],
        },

        {
          path: 'layouts',
          children: [
            { element: <Navigate to="/layouts/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard roles={[2,3]} hasContent><PageLayoutsList /></RoleBasedGuard> },
            { path: 'design/:uid', element: <RoleBasedGuard roles={[2,3]} hasContent><PageLayoutsDesign /></RoleBasedGuard> },
            { path: 'design', element: <RoleBasedGuard roles={[2,3]} hasContent><PageLayoutsDesign /></RoleBasedGuard> },
          ],
        },
        {
          path: 'triggers',
          children: [
            { element: <Navigate to="/triggers/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard roles={[2,3]} hasContent><PageTriggersList /></RoleBasedGuard> },
          ],
        },
        { path: 'shelf-labels', element: <RoleBasedGuard roles={[3]} hasContent><PageShelfLabels /></RoleBasedGuard> },

        {
          path: 'analytics',
          children: [
            { element: <Navigate to="/analytics/traffic" replace />, index: true },
            { path: 'traffic', element: <RoleBasedGuard roles={[2,3]} hasContent><PageAnalyticsTraffic /></RoleBasedGuard> },
            { path: 'demographic', element: <RoleBasedGuard roles={[2,3]} hasContent><PageAnalyticsDemographic /></RoleBasedGuard> },
          ],
        },

        {
          path: 'reports-content',
          children: [
            { element: <Navigate to="/reports-content/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard roles={[1,2,3]} hasContent><PageReportsList /></RoleBasedGuard> },
          ],
        },
        {
          path: 'reports-triggers',
          children: [
            { element: <Navigate to="/reports-triggers/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard roles={[2,3]} hasContent><PageTriggerReportsList /></RoleBasedGuard> },
          ],
        },
        {
          path: 'users',
          children: [
            { element: <Navigate to="/users/manage" replace />, index: true },
            { path: 'manage', element: <RoleBasedGuard roles={[1,2,3]} hasContent><PageUserSettings /></RoleBasedGuard> },
            { path: 'licences', element: <RoleBasedGuard roles={[1,2,3]} hasContent><PageLicencesList /></RoleBasedGuard> },
            { path: 'licences/manage/:uid', element: <RoleBasedGuard roles={[1,2,3]} hasContent><PageLicenceManage /></RoleBasedGuard> },
          ],
        },

        {
          path: 'arthur-campaigns',
          children: [
            { element: <Navigate to="/arthur-campaigns/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard roles={[3]} hasContent><PageArthurCampaignsList /></RoleBasedGuard> },
            { path: 'manage/:uid', element: <RoleBasedGuard roles={[3]} hasContent><PageArthurCampaignManage /></RoleBasedGuard> },
          ],
        },
        {
          path: 'arthur-products',
          children: [
            { element: <Navigate to="/arthur-products/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard roles={[3]} hasContent><PageArthurProductsList /></RoleBasedGuard> },
            { path: 'manage/:uid', element: <RoleBasedGuard roles={[3]} hasContent><PageArthurProductManage /></RoleBasedGuard> },
          ],
        },
        {
          path: 'arthur-prizes',
          children: [
            { element: <Navigate to="/arthur-prizes/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard roles={[3]} hasContent><PageArthurPrizesList /></RoleBasedGuard> },
            { path: 'manage/:uid', element: <RoleBasedGuard roles={[3]} hasContent><PageArthurPrizeManage /></RoleBasedGuard> },
          ],
        },
        {
          path: 'arthur-machines',
          children: [
            { element: <Navigate to="/arthur-machines/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard roles={[3]} hasContent><PageArthurMachinesList /></RoleBasedGuard> },
            { path: 'manage/:uid', element: <RoleBasedGuard roles={[3]} hasContent><PageArthurMachineManage /></RoleBasedGuard> },
          ],
        },

      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}


// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// Dashboard
const PageScreensList = Loadable(lazy(() => import('../pages/PageScreensList')));
const PageScreenManage = Loadable(lazy(() => import('../pages/PageScreenManage')));

// Dashboard
const PageVideoWallsList = Loadable(lazy(() => import('../pages/PageVideoWallsList')));
const PageVideoWallManage = Loadable(lazy(() => import('../pages/PageVideoWallManage')));

const PagePlaylistsList = Loadable(lazy(() => import('../pages/PagePlaylistsList')));
const PagePlaylistManage = Loadable(lazy(() => import('../pages/PagePlaylistManage')));

const PageWbcList = Loadable(lazy(() => import('../pages/PageWbcList')));
const PageWbcManage = Loadable(lazy(() => import('../pages/PageWbcManage')));

const PageMediaList = Loadable(lazy(() => import('../pages/PageMediaList')));

const PageLayoutsList = Loadable(lazy(() => import('../pages/PageLayoutsList')));
const PageLayoutsDesign = Loadable(lazy(() => import('../pages/PageLayoutsDesign')));

const PageArthurCampaignsList = Loadable(lazy(() => import('../pages/PageArthurCampaignsList')));
const PageArthurCampaignManage = Loadable(lazy(() => import('../pages/PageArthurCampaignManage')));

const PageArthurProductsList = Loadable(lazy(() => import('../pages/PageArthurProductsList')));
const PageArthurProductManage = Loadable(lazy(() => import('../pages/PageArthurProductManage')));

const PageArthurPrizesList = Loadable(lazy(() => import('../pages/PageArthurPrizesList')));
const PageArthurPrizeManage = Loadable(lazy(() => import('../pages/PageArthurPrizeManage')));

const PageArthurMachinesList = Loadable(lazy(() => import('../pages/PageArthurMachinesList')));
const PageArthurMachineManage = Loadable(lazy(() => import('../pages/PageArthurMachineManage')));

const PageTriggersList = Loadable(lazy(() => import('../pages/PageTriggersList')));
const PageShelfLabels = Loadable(lazy(() => import('../pages/PageShelfLabels')));

const PageAnalyticsTraffic = Loadable(lazy(() => import('../pages/PageAnalyticsTraffic')));
const PageAnalyticsDemographic = Loadable(lazy(() => import('../pages/PageAnalyticsDemographic')));

const PageReportsList = Loadable(lazy(() => import('../pages/PageReportsList')));
const PageTriggerReportsList = Loadable(lazy(() => import('../pages/PageTriggerReportsList')));

const PageUserSettings = Loadable(lazy(() => import('../pages/PageUserSettings')));
const PageLicencesList = Loadable(lazy(() => import('../pages/PageLicencesList')));
const PageLicenceManage = Loadable(lazy(() => import('../pages/PageLicenceManage')));


const NotFound = Loadable(lazy(() => import('../pages/Page404')));
