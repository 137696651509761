// components
import Iconify from '../../../components/Iconify';


// ----------------------------------------------------------------------


const ICONS = {
  screens: <Iconify icon={'ic:baseline-connected-tv'} /> ,
  playlist: <Iconify icon={'ic:sharp-playlist-play'} />,
  media: <Iconify icon={'ic:outline-perm-media'} /> ,
  layouts: <Iconify icon={'ic:round-view-quilt'} /> ,
  triggers: <Iconify icon={'ic:baseline-touch-app'} /> ,
  shelfLabels: <Iconify icon={'ic:round-price-change'} /> ,
  reports: <Iconify icon={'ic:baseline-bar-chart'} /> ,
  analytics: <Iconify icon={'ic:baseline-people'} /> ,

  videoWall: <Iconify icon={'ic:outline-grid-view'} /> ,
  webBannerCampaigns: <Iconify icon={'ic:baseline-web'} />
};

const navConfig = [
  // General digital signage controls
  // ----------------------------------------------------------------------
  {
    subheader: 'digital signage',
    items: [
      { title: 'Screens', path: '/screens', icon: ICONS.screens, permission: 1, },
      { title: 'Video-Walls (Beta!)', path: '/video-walls', icon: ICONS.videoWall, permission: 32768 },
      { title: 'Playlists', path: '/playlists', icon: ICONS.playlist, permission: 64 },
      { title: 'Media', path: '/media', icon: ICONS.media, permission: 8 },
    ],
  },
  // Vending Sampling-------------------------------------------------------
  {
    subheader: 'Arthur Sampling',
    items: [
      { title: 'Campaigns', path: '/arthur-campaigns', icon: ICONS.layouts, permission: 262144 },
      { title: 'Products', path: '/arthur-products', icon: ICONS.triggers, permission: 262144 },
      { title: 'Prizes', path: '/arthur-prizes', icon: ICONS.shelfLabels, permission: 262144 },
      { title: 'Machines', path: '/arthur-machines', icon: ICONS.analytics, permission: 262144 },
    ],
  },
  // Advanced entities creation
  // ----------------------------------------------------------------------
  {
    subheader: 'Advanced digital signage',
    items: [
      { title: 'Layouts (beta!)', path: '/layouts', icon: ICONS.layouts, permission: 512,
      children: [
        { title: 'List', path: '/layouts/list', permission: 512,},
        { title: 'Design', path: '/layouts/design', permission: 1024,},
      ]},
      { title: 'Content Triggers', path: '/triggers', icon: ICONS.triggers, permission: 4096 },
      { title: 'Web Banner Campaigns', path: '/wbc', icon: ICONS.webBannerCampaigns, permission: 32768  },
      // { title: 'Shelf-Labels', path: '/shelf-labels', icon: ICONS.shelfLabels },
    ],
  },
  // Advanced entities creation
  {
    subheader: 'AI Analytics',
    items: [
      { title: 'Traffic', path: '/analytics/traffic', icon: ICONS.analytics, permission: 2097152},
      { title: 'Demographic', path: '/analytics/demographic', icon: ICONS.analytics, permission: 2097152},
    ],
  },
  // ----------------------------------------------------------------------
  {
    subheader: 'Reports',
    items: [
      { title: 'Media reports', path: '/reports-content', icon: ICONS.reports, permission: 8 },
      { title: 'Trigger reports', path: '/reports-triggers', icon: ICONS.reports,  permission: 4096 },
    ],
  },
];

export default navConfig;
