import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const defaultFilterValues = {
  perPage: "32",
  page: "1",
  orderBy: "1",
  searchBy: "1",
  searchTerm: "",
}

const initialState = {
  isLoading: false,
  error: null,
  wbcs: [],
  wbc: {},
  filters: {...defaultFilterValues},
  listTotalPages: 1,
  mediaPreview: true,
  disabledSaveMedia: true,
};

const slice = createSlice({
  name: 'wbc',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getWbcsSuccess(state, action) {
      state.isLoading = false;
      state.wbcs = action.payload.entries;
      state.listTotalPages = action.payload.totalPages;
    },

    // GET PRODUCT
    getWbcSuccess(state, action) {
      state.isLoading = false;
      state.wbc = action.payload;
    },

    updateFilters(state, action) {
      state.filters.perPage = action.payload.perPage;
      state.filters.page = action.payload.page;
      state.filters.orderBy = action.payload.orderBy;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchTerm = action.payload.searchTerm;
    },

    setWbc(state, action) {
      state.wbc = action.payload;
    },

   
    toggleMediaPreview(state){
      state.mediaPreview = !state.mediaPreview;
    },
    resetSaveButtonsState(state){
      state.disabledSaveMedia = true;
      state.disabledSaveRules = true;
    },
    setSaveMediaButtonState(state, action){
      state.disabledSaveMedia = !action.payload;
    },
    clearFilterInputs(state) {
      state.filters = {...defaultFilterValues}
    },
    
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setWbc,
  resetSaveButtonsState,
  toggleMediaPreview,
  setSaveMediaButtonState,
  clearFilterInputs,
} = slice.actions;

// ----------------------------------------------------------------------

export function getWbcs(filters=initialState.filters, updateFilters=true) {  
  return async () => {
    dispatch(slice.actions.startLoading());
    if(updateFilters){dispatch(slice.actions.updateFilters(filters))};

    try {
      const response = await axios.get(`${HOST_API}/api-v2/wbc/list`, {
        params: filters
      });
      
      dispatch(slice.actions.getWbcsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getWbc(uid) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${HOST_API}/api-v2/wbc/manage`, {
        params: { uid },
      });
      
      dispatch(slice.actions.getWbcSuccess(response.data.entries));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function registerWbc(name, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${HOST_API}/api-v2/wbc/manage`, {
        name
      });

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------
  
function mediaToMediaItem(_mediaItem, order){
  return {
    uid: _mediaItem.uid,
    type: 'media',
    order
  }
}

export function publishWbcMedia(wbc, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      
      await axios.post(`${HOST_API}/api-v2/wbc/manage/media`, {
        uid: wbc.uid,
        media: wbc.media.map((mediaItem, index) => mediaToMediaItem(mediaItem, index)),
        banners_metadata: wbc?.banners_metadata ? wbc.banners_metadata : {}
      });

      dispatch(slice.actions.endLoading());
      dispatch(slice.actions.setWbc(wbc));
      
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------

export function deleteWbc(wbc, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${HOST_API}/api-v2/wbc/manage/${wbc.uid}`);

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------

export function updateWbcName(wbc, name, snackBarCallback) {
  return async () => {
    try {
      await axios.post(`${HOST_API}/api-v2/wbc/manage/name`, {
        uid: wbc.uid,
        name
      });

      const updatedWbc = {
        ...wbc,
        name
      }

      dispatch(slice.actions.setWbc(updatedWbc));
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateWbcSchedule(wbc, schedule, snackBarCallback) {
  return async () => {
    try {
      await axios.post(`${HOST_API}/api-v2/wbc/manage/schedule`, {
        uid: wbc.uid,
        schedule
      });

      const updatedWbc = {
        ...wbc,
        schedule
      }

      dispatch(slice.actions.setWbc(updatedWbc));
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}