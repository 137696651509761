import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const defaultFilterValues = {
  perPage: "32",
  page: "1",
  orderBy: "1",
  searchBy: "1",
  searchTerm: "",
}

const initialState = {
  isLoading: false,
  error: null,
  licences: [],
  licence: {},
  filters: {...defaultFilterValues},
  listTotalPages: 1,
  disabledSaveScreens: true,
  selectionExceedsSeats: false,
};

const slice = createSlice({
  name: 'licence',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getLicencesSuccess(state, action) {
      state.isLoading = false;
      state.licences = action.payload.entries;
      state.listTotalPages = action.payload.totalPages;
    },

    // GET PRODUCT
    getLicenceSuccess(state, action) {
      state.isLoading = false;
      state.licence = action.payload;
    },

    updateFilters(state, action) {
      state.filters.perPage = action.payload.perPage;
      state.filters.page = action.payload.page;
      state.filters.orderBy = action.payload.orderBy;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchTerm = action.payload.searchTerm;
    },

    setLicence(state, action) {
      state.licence = action.payload;
      state.selectionExceedsSeats = action.payload.screens.length > action.payload.current_history.seats;
    },
    clearFilterInputs(state) {
      state.filters = {...defaultFilterValues}
    },
    setSaveScreensButtonState(state, action) {
      state.disabledSaveScreens = !action.payload;
    },
    resetSaveButtonsState(state){
      state.disabledSaveScreens = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  clearFilterInputs,
  setSaveScreensButtonState,
  resetSaveButtonsState,
  setSelectionExceedsSeats,
  setLicence
} = slice.actions;

// ----------------------------------------------------------------------

export function getLicences(filters, updateFilters=true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if(updateFilters){dispatch(slice.actions.updateFilters(filters))};

    try {
      const response = await axios.get(`${HOST_API}/api-v2/users/licence_plans/list`, {
        params: filters
      });
      
      dispatch(slice.actions.getLicencesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLicence(uid) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${HOST_API}/api-v2/users/licence_plans/manage`, {
        params: { uid },
      });

      dispatch(slice.actions.getLicenceSuccess(response.data.entries));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function publishLicenceScreens(licence, snackBarCallback) {
  return async () => {
    try {
      dispatch(slice.actions.setSaveScreensButtonState(false));
      
      await axios.post(`${HOST_API}/api-v2/users/licence_plans/manage/screens`, {
        uid: licence.uid,
        screens: licence.screens.map(screen => screen.device_id)
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}